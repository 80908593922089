import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/business/BusinessView.vue'),
  },

  {
    path: '/investors',
    name: 'investor',
    component: () => import('../views/investors/InvestorsView.vue'),
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/AboutView.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/business',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
